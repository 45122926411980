var render = function () {
  var _vm$organizer, _vm$organizer2, _vm$organizer3, _vm$organizer3$wallet, _vm$me, _vm$me$organizers, _vm$me2, _vm$me2$organizers, _vm$organizer4, _vm$organizer4$tourna, _vm$organizer5, _vm$organizer6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.organizerLoading ? _c('div', [_c('b-row', [_c('b-col', [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 1)], 1) : _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', [!_vm.me ? _c('b-row', [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": _vm.me && _vm.me.avatar_image ? _vm.me.avatar_image : null,
      "size": "90px",
      "square": ""
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "8",
      "sm": "12"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.me && _vm.me.name ? _vm.me.name : null))]), _c('div', [_vm._v(_vm._s(_vm.me && _vm.me.email && _vm.me.email || null))]), _c('div', {
    staticClass: "mt-1"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "to": "/",
      "variant": "primary"
    }
  }, [_vm._v(" Back to platform ")])], 1)])], 1)], 1), _c('b-col', [_c('organizer-profile-edit-modal', {
    attrs: {
      "organizer": _vm.organizer,
      "loading": _vm.organizerLoading
    }
  }), _c('div', [_c('h5', [_vm._v("Currently selected organisation")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "src": ((_vm$organizer = _vm.organizer) === null || _vm$organizer === void 0 ? void 0 : _vm$organizer.avatar_image) || null,
      "size": "50px"
    }
  }), _c('h5', [_vm._v(" " + _vm._s(((_vm$organizer2 = _vm.organizer) === null || _vm$organizer2 === void 0 ? void 0 : _vm$organizer2.name) || null) + " ")]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleEditOrganizerClick
    }
  }, [_vm._v(" edit ")])], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-1"
  }, [_c('b-row', {
    staticClass: "flex-grow-1 align-items-center"
  }, [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "wallet-badge"
  }, [_c('b-badge', {
    staticClass: "p-sm-1 font-medium-2",
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(((_vm$organizer3 = _vm.organizer) === null || _vm$organizer3 === void 0 ? void 0 : (_vm$organizer3$wallet = _vm$organizer3.wallet) === null || _vm$organizer3$wallet === void 0 ? void 0 : _vm$organizer3$wallet.balance) || 0) + " € ")])], 1)]), _c('b-col', {
    staticClass: "mt-1 mt-lg-0 d-flex justify-content-between justify-content-lg-start",
    attrs: {
      "md": "7"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "disabled": "disabled",
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleAddFundsClick
    }
  }, [_vm._v(" Add funds ")]), _c('b-button', {
    attrs: {
      "disabled": "disabled",
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleWithdrawFundsClick
    }
  }, [_vm._v(" Withdraw funds ")])], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-1 flex-grow-1"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.handleCreateNewTournament
    }
  }, [_vm._v(" Create new tournament ")])], 1)], 1), _c('b-col', [_c('div', [_c('h5', [_vm._v("Your other organisations")])]), ((_vm$me = _vm.me) === null || _vm$me === void 0 ? void 0 : (_vm$me$organizers = _vm$me.organizers) === null || _vm$me$organizers === void 0 ? void 0 : _vm$me$organizers.filter(function (org) {
    return org.id !== _vm.organizer.id;
  }).length) === 0 ? _c('div', [_c('p', {
    staticClass: "font-italic"
  }, [_vm._v(" No other organizations available. ")])]) : _vm._e(), _vm._l((_vm$me2 = _vm.me) === null || _vm$me2 === void 0 ? void 0 : (_vm$me2$organizers = _vm$me2.organizers) === null || _vm$me2$organizers === void 0 ? void 0 : _vm$me2$organizers.filter(function (org) {
    return org.id !== _vm.organizer.id;
  }), function (organization) {
    return _c('div', {
      key: organization.id,
      staticClass: "d-flex mb-1"
    }, [_c('b-col', [_c('b-avatar', {
      attrs: {
        "src": organization === null || organization === void 0 ? void 0 : organization.avatar_image,
        "size": "30px"
      }
    })], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_vm._v(" " + _vm._s(organization.name) + " ")]), _c('b-col', {
      attrs: {
        "md": "5"
      }
    }, [_c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.handleSwitchOrganization(organization.id);
        }
      }
    }, [_vm._v(" Switch ")])], 1)], 1);
  })], 2)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('organizer-team')], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "8",
      "md": "6",
      "sm": "12"
    }
  }, [((_vm$organizer4 = _vm.organizer) === null || _vm$organizer4 === void 0 ? void 0 : (_vm$organizer4$tourna = _vm$organizer4.tournaments) === null || _vm$organizer4$tourna === void 0 ? void 0 : _vm$organizer4$tourna.length) === 0 ? _c('b-card', [_c('div', {
    staticClass: "alert-body"
  }, [_c('span', {
    staticClass: "mr-25"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "sInfoIcon"
    }
  })], 1), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v("No planned tournaments yet.")])])]) : _vm._e(), _c('b-row', [_c('edit-tournament-staff-modal', {
    attrs: {
      "organizer-members": (_vm$organizer5 = _vm.organizer) === null || _vm$organizer5 === void 0 ? void 0 : _vm$organizer5.members,
      "tournament": _vm.currentTournament
    },
    on: {
      "tournamentStaffUpdated": _vm.handleTournamentStaffUpdated
    }
  }), _vm._l((_vm$organizer6 = _vm.organizer) === null || _vm$organizer6 === void 0 ? void 0 : _vm$organizer6.tournaments, function (tournament) {
    return _c('b-col', {
      key: tournament.id,
      staticClass: "d-flex align-items-stretch",
      attrs: {
        "lg": "4",
        "sm": "12"
      }
    }, [_c('organizer-tournament-card', {
      key: tournament.id,
      attrs: {
        "tournament": tournament
      },
      on: {
        "editTournamentStaffClick": _vm.showStaffEditModal
      }
    })], 1);
  })], 2)], 1), _c('b-col', {
    attrs: {
      "lg": "4",
      "md": "6",
      "sm": "12"
    }
  }, [_c('organizer-dashboard-activity', {
    attrs: {
      "disputes": _vm.disputes
    }
  })], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }