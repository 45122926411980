<template>
  <b-modal
    id="organizer-profile-modal-edit"
    no-close-on-backdrop
    size="xl"
    title="Edit organizer"
    @ok="handleOk"
    @hidden="onClose"
  >
    <template #modal-footer="{ cancel, ok }">
      <b-row class="w-100 mr-1 ml-1 justify-content-start">
        <dismissible-message-box
          :auto-dismiss="6000"
          :show="showMessageBox"
          :variant="messageboxType"
          class="p-1 w-100"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          {{ message }}
        </dismissible-message-box>
      </b-row>
      <b-row class="justify-content-between w-100 mr-1 ml-1">
        <spinning-button
          :is-loading="isLoading"
          type="submit"
          variant="primary"
          @click.native="ok"
        >
          Update profile
        </spinning-button>
        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Close
        </b-button>
      </b-row>
    </template>

    <b-container>
      <validation-observer ref="observer">
        <b-form
          enctype="multipart/form-data"
          @submit.stop.prevent="handleSubmit"
        >
          <b-row class="d-flex justify-space-around">
            <b-col md="4">
              <h5>Organizer info</h5>

              <b-form-group>
                <label>Name</label>
                <validation-provider
                  v-slot="{ errors: componentErrors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    v-model="organizerLocal.name"
                    name="name"
                    placeholder="eSports"
                  />
                  <small class="text-danger">{{ componentErrors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="email">Email</label>
                <validation-provider
                  v-slot="{ errors: componentErrors }"
                  name=" email"
                  rules="email"
                >
                  <b-form-input
                    v-model="organizerLocal.email"
                    name="email"
                    placeholder="esports@example.com"
                  />
                  <small class="text-danger">{{ componentErrors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <h5>Profile</h5>
              <!-- Avatar Upload -->
              <div class="d-flex justify-content-between">
                <div class="team-avatar mr-1">
                  <b-avatar
                    :src="organizerLocal.avatar_image"
                    button
                    size="100px"
                    square
                    variant="primary"
                    @click="openAvatarFilePopup"
                  />
                </div>
                <div class="team-avatar-upload-container">
                  <div class="d-flex mb-1 flex-wrap justify-content-around">
                    <b-button
                      size="sm"
                      variant="primary"
                      @click="openAvatarFilePopup"
                    >
                      Upload
                    </b-button>

                    <b-button
                      cla
                      size="sm"
                      variant="outline-primary"
                      @click="clearAvatar"
                    >
                      <b-spinner
                        v-if="removingAvatar"
                        small
                      />
                      <span v-else>Reset</span>
                    </b-button>
                  </div>

                  <label>Allowed JPG, GIF or PNG. Max size of 1MB</label>
                  <b-form-file
                    v-show="false"
                    ref="avatarFileInput"
                    v-model="avatar"
                    class="mt-1"
                    plain
                  />
                </div>
              </div>
              <!--/ Avatar Upload -->
            </b-col>
            <b-col md="4">
              <!-- Social Media Input -->
              <h5>Social media</h5>
              <b-form-group>
                <label for="discord">Discord</label>

                <b-form-input
                  v-model="organizerLocal.discord.url"
                  name="discord"
                  placeholder="Enter username, like Clash#1337"
                />
              </b-form-group>

              <b-form-group>
                <label for="instagram">Instagram</label>
                <b-form-input
                  v-model="organizerLocal.instagram.url"
                  name="instagram"
                  placeholder="Enter instagram username"
                />
              </b-form-group>

              <!-- Twitter -->
              <b-form-group>
                <label for="twitter">Twitter</label>

                <b-form-input
                  v-model="organizerLocal.twitter.url"
                  name="twitter"
                  placeholder="Enter facebook username"
                />
              </b-form-group>

              <!-- Facebook -->
              <b-form-group>
                <label for="facebook">Facebook</label>

                <b-form-input
                  v-model="organizerLocal.facebook.url"
                  name="facebook"
                  placeholder="Enter facebook username"
                />
              </b-form-group>
              <!--/ Social Media Input  -->
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCol, BContainer,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BModal,
  BRow, BSpinner,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import LinkType from '@/constants/LinkType'
import { email, required } from '@core/utils/validations/validations'
import LinkEntityType from '@/constants/LinkEntityType'
import MessageType from '@/constants/MessageType'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import SpinningButton from '@/components/SpinningButton.vue'
import store from '@/store'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BContainer,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormFile,
    BButton,
    BAvatar,
    SpinningButton,
    DismissibleMessageBox,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    organizer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      email,
      required,
      MessageType,
      organizerLocal: {
        name: this.organizer?.name || null,
        email: this.organizer?.email || null,
        avatar_image: this.organizer?.avatar_image || null,
        discord: this.organizer?.links?.find(
          link => link.link_type_id === LinkType.DISCORD,
        ) || {
          id: null,
          url: null,
          link_type_id: LinkType.DISCORD,
        },
        instagram: this.organizer?.links?.find(
          link => link.link_type_id === LinkType.INSTAGRAM,
        ) || {
          id: null,
          url: null,
          link_type_id: LinkType.INSTAGRAM,
        },
        twitter: this.organizer?.links?.find(
          link => link.link_type_id === LinkType.TWITTER,
        ) || {
          id: null,
          url: null,
          link_type_id: LinkType.TWITTER,
        },
        facebook: this.organizer?.links?.find(
          link => link.link_type_id === LinkType.FACEBOOK,
        ) || {
          id: null,
          url: null,
          link_type_id: LinkType.FACEBOOK,
        },
      },
      showMessageBox: false,
      messageboxType: null,
      message: null,
      isLoading: false,
      avatar: null,
      removingAvatar: false,
    }
  },
  computed: {
    hasAvatar() {
      return !!this.avatar
    },
  },
  watch: {
    avatar(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then(value => {
              this.organizerLocal.avatar_image = value
            })
            .catch(() => {
              this.organizerLocal.avatar_image = null
            })
        } else {
          this.organizerLocal.avatar_image = null
        }
      }
    },
    organizer(value) {
      const { discord } = this.organizerLocal
      const { instagram } = this.organizerLocal
      const { twitter } = this.organizerLocal
      const { facebook } = this.organizerLocal

      this.organizerLocal = value

      this.organizerLocal.twitter = this.organizerLocal?.links?.find(
        link => link.link_type_id === LinkType.TWITTER,
      ) || twitter

      this.organizerLocal.facebook = this.organizerLocal?.links?.find(
        link => link.link_type_id === LinkType.FACEBOOK,
      ) || facebook

      this.organizerLocal.instagram = this.organizerLocal?.links?.find(
        link => link.link_type_id === LinkType.INSTAGRAM,
      ) || instagram

      this.organizerLocal.discord = this.organizerLocal?.links?.find(
        link => link.link_type_id === LinkType.DISCORD,
      ) || discord
    },
  },
  methods: {
    async clearAvatar() {
      this.removingAvatar = true
      await this.$api.organizer.organizerRemoveAvatar(this.organizer.id)
      this.removingAvatar = false

      this.organizerLocal.avatar_image = null
      this.avatar = null
    },
    openAvatarFilePopup() {
      this.$refs.avatarFileInput.$el.click()
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(data)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async onClose() {
      await store.dispatch('organizer/fetchDashboardOrganizer', this.$route.params.id)
    },
    async handleOk(event) {
      event.preventDefault()

      this.message = null
      this.showMessageBox = false

      const formValid = await this.$refs.observer.validate()
      if (!formValid) {
        return
      }

      const payload = {
        user_id: this.organizer.owner.id,
        id: this.organizer.id,
        name: this.organizerLocal.name,
        email: this.organizerLocal.email,
        avatar: this.avatar,
        links: [],
      }

      if (
          this.organizerLocal?.facebook?.id
          || this.organizerLocal.facebook?.url
      ) {
        payload.links.push({
          id: this.organizerLocal?.facebook.id || null,
          url: this.organizerLocal?.facebook.url,
          link_type_id: this.organizerLocal?.facebook.link_type_id,
          link_entity_id: this.organizer.id,
          link_entity_type: LinkEntityType.ORGANIZER,
        })
      }

      if (
          this.organizerLocal.twitter?.id
          || this.organizerLocal.twitter?.url
      ) {
        payload.links.push({
          id: this.organizerLocal.twitter.id || null,
          url: this.organizerLocal.twitter.url,
          link_type_id: this.organizerLocal.twitter.link_type_id,
          link_entity_id: this.organizer.id,
          link_entity_type: LinkEntityType.ORGANIZER,
        })
      }

      if (
          this.organizerLocal.discord?.id
          || this.organizerLocal.discord?.url
      ) {
        payload.links.push({
          id: this.organizerLocal.discord.id || null,
          url: this.organizerLocal.discord.url,
          link_type_id: this.organizerLocal.discord.link_type_id,
          link_entity_id: this.organizer.id,
          link_entity_type: LinkEntityType.ORGANIZER,
        })
      }

      if (
          this.organizerLocal.instagram?.id
          || this.organizerLocal.instagram?.url
      ) {
        payload.links.push({
          id: this.organizerLocal.instagram.id || null,
          url: this.organizerLocal.instagram.url,
          link_type_id: this.organizerLocal.instagram.link_type_id,
          link_entity_id: this.organizer.id,
          link_entity_type: LinkEntityType.ORGANIZER,
        })
      }

      this.isLoading = true

      const { errors } = await this.$api.organizer.organizerUpdateProfile(payload)

      this.isLoading = false

      if (errors) {
        if (errors[0].extensions.code === 'DATA_CONFLICT_ERROR') {
          this.message = 'Organizer with this name already exists!'
        } else {
          this.message = 'There was a problem updating your organization. Please try again or contact our support.'
        }

        this.messageboxType = MessageType.ERROR
        this.showMessageBox = true

        return
      }

      this.messageboxType = MessageType.SUCCESS
      this.showMessageBox = true
      this.message = 'Your organization has been updated successfully.'
    },
  },
}
</script>

<style lang="scss">
.modal-xl {
  margin-left: 17%;
  margin-right: 17%;
}

.modal .modal-header .close {
  transform: translate(-4px, 5px);

  &:hover,
  &:focus,
  &:active {
    transform: translate(-7px, 6px);
  }
}
</style>
